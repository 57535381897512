import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "../chekoutForm/chekoutForm";
import * as StyledComponents from "./shippingForm.module.scss";
import stateData from "../../../data/state.json";
import { getVehicle, getMVehicle } from "../cartFunctions";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import {
  insertAdress,
  enterCC,
  modifyShippingBtn,
} from "../../setDisplayStates/functions";

//redux
import { useSelector, useDispatch } from "react-redux";
import allActions from "../../redux/actions";

export const ShippingForm = () => {
  //redux
  const NewCartContent = useSelector((state) => state.newCart);
  const DisplayStates = useSelector((state) => state.setDisplayStates);
  const dispatch = useDispatch();

  //round 2 decimals perfect
  function roundNumber(numberToRound) {
    let res = Math.round((numberToRound + Number.EPSILON) * 100) / 100;
    return res;
  }

  //YUP shema
  const schema = yup
    .object({
      fname: yup.string().required(),
      fphone: yup.string().required(),
      femail: yup.string().email().required(),
      fcity: yup.string().required(),
      faddress: yup.string().required(),
      fzip: yup.string().required(),
      //fstate: yup.string().required(),
    })
    .required();

  // resolver and register form
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  //submit form
  const onSubmit = (data) => {
    // console.log("SEND");
    // console.log(data);

    let newDisplayStates = enterCC();
    dispatch(allActions.setDisplayStates.setDisplayStates(newDisplayStates));
    //createPaymentIntent();
    updatePaymentIntent();
  };

  function insertShipping() {
    let newDisplayStates = insertAdress();
    dispatch(allActions.setDisplayStates.setDisplayStates(newDisplayStates));
    createPaymentIntent();
  }

  function modifShipping() {
    let newDisplayStates = modifyShippingBtn();
    dispatch(allActions.setDisplayStates.setDisplayStates(newDisplayStates));
  }

  //create the payment intent
  function createPaymentIntent() {
    //get car details
    let vehSel = getMVehicle();
    vehSel = JSON.stringify(vehSel.vehicle);
    // console.log("vehSel");
    // console.log(vehSel);

    //set newCart
    let newCart = [];
    NewCartContent.wheels.map((item) => {
      if (item.sku !== undefined) {
        let product = {
          sku: item.sku,
          price: item.price,
          qty: item.total,
        };
        newCart.push(product);
      }
    });
    NewCartContent.tires.map((item) => {
      if (item.sku !== undefined) {
        let product = {
          sku: item.sku,
          price: item.price,
          qty: item.total,
        };
        newCart.push(product);
      }
    });
    newCart = JSON.stringify(newCart);
    // console.log(newCart);

    //set the date
    const d = new Date();
    let newdate =
      d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate();

    //set the new metadata
    let metadata = {
      date: newdate,
      cart: newCart,
      tires: NewCartContent.totalTires,
      nuts: NewCartContent.nuts.qty,
      tpms: NewCartContent.tpms.qty,
      subtotal: NewCartContent.subTotal,
      discount: NewCartContent.discountAmount,
      tps: NewCartContent.tps,
      tvq: NewCartContent.tvq,
      total: NewCartContent.total,
      vehicle: vehSel,
    };

    fetch("https://www.wheels-api.com/stripe/create-payment-intent", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        items: [
          {
            price: roundNumber(NewCartContent.total * 100),
            metadata: metadata,
          },
        ],
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        setClientSecret(data.clientSecret);
        setpaymentID(data.id);
      });
  }

  //update the payment intent
  function updatePaymentIntent() {
    //get car details
    let vehSel = getMVehicle();
    vehSel = JSON.stringify(vehSel.vehicle);
    // console.log("vehSel");
    // console.log(vehSel);

    //set newCart
    let newCart = [];
    NewCartContent.wheels.map((item) => {
      if (item.sku !== undefined) {
        let product = {
          sku: item.sku,
          price: item.price,
          qty: item.total,
        };
        newCart.push(product);
      }
    });
    NewCartContent.tires.map((item) => {
      if (item.sku !== undefined) {
        let product = {
          sku: item.sku,
          price: item.price,
          qty: item.total,
        };
        newCart.push(product);
      }
    });
    newCart = JSON.stringify(newCart);
    // console.log(newCart);

    //set the date
    const d = new Date();
    let newdate =
      d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate();

    //set the new metadata
    let metadata = {
      date: newdate,
      cart: newCart,
      tires: NewCartContent.totalTires,
      nuts: NewCartContent.nuts.qty,
      tpms: NewCartContent.tpms.qty,
      subtotal: NewCartContent.subTotal,
      discount: NewCartContent.discountAmount,
      tps: NewCartContent.tps,
      tvq: NewCartContent.tvq,
      total: NewCartContent.total,
      vehicle: vehSel,
      email: Email,
      message: Message,
    };

    fetch("https://www.wheels-api.com/stripe/update-payment-intent", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        items: [
          {
            pi: paymentID,
            price: NewCartContent.subTotal,
            receipt_email: Email,
            metadata: metadata,
            shipping: {
              name: Name,
              phone: Phone,
              address: {
                city: City,
                country: "CA",
                line1: Adress,
                line2: "",
                postal_code: PostalCode,
                state: State,
              },
            },
          },
        ],
      }),
    }).then((res) => res.json());
  }

  //

  //const for shipping adress
  const [Name, setName] = useState("");
  const [Phone, setPhone] = useState("");
  const [City, setCity] = useState("");
  const [Adress, setAdress] = useState("");
  const [PostalCode, setPostalCode] = useState("");
  const [State, setState] = useState("");
  const [Message, setMessage] = useState("");
  const [Email, setEmail] = useState("");

  //format email
  function emailFormat(givenEmail) {
    givenEmail = givenEmail.replace(/ /g, "");
    // console.log(givenEmail);
    setEmail(givenEmail);
  }

  //for the CC form
  const stripePromise = loadStripe(
    "pk_live_51K46sCCETZZkd7IPswwzQXh9jKuU2Jjdd6gysSsbVo35CIFBLcHFiqvR8pY5ISuZRvnA2uW24QyGkpq9QOAWVmso00OUM7Cmq6"
  );
  const [clientSecret, setClientSecret] = useState("");
  const [paymentID, setpaymentID] = useState("");
  const appearance = {
    theme: "stripe",
    variables: {
      colorPrimary: "#869736",
      colorBackground: "#ffff",
    },
  };
  const options = {
    clientSecret,
    appearance,
  };
  return (
    <>
      <div
        className={StyledComponents.ckbtn}
        style={DisplayStates.insertAdressbtn}
      >
        <button onClick={() => insertShipping()}>
          <p>Insérer l'adresse d'expédition</p>
        </button>
      </div>
      <div
        className={StyledComponents.ckbtn}
        style={DisplayStates.changeShippingBtn}
      >
        <button onClick={() => modifShipping()}>
          <p>Modifier l'adresse d'expidition</p>
        </button>
      </div>
      <div style={DisplayStates.shippingForm}>
        <h3 className={StyledComponents.addressTitle}>Adresse d'expédition</h3>

        {/* FORM */}
        <form
          className={StyledComponents.shipingForm}
          onSubmit={handleSubmit(onSubmit)}
        >
          <label htmlFor="name">Nom complet :</label>
          <input
            {...register("fname")}
            id="name"
            placeholder="Votre nom"
            value={Name}
            onChange={(e) => setName(e.target.value)}
          />
          <p className={StyledComponents.error}>
            {errors.fname?.type === "required" &&
              "⚠ Veuillez rentrer votre nom"}
          </p>

          <label htmlFor="phone">Numéro de téléphone :</label>
          <input
            {...register("fphone")}
            id="phone"
            placeholder="123 555 5555"
            value={Phone}
            onChange={(e) => setPhone(e.target.value)}
          />
          <p className={StyledComponents.error}>
            {errors.fphone?.type === "required" &&
              "⚠ Veuillez rentrer votre numéro de téléphone"}
          </p>

          <label htmlFor="email">Courriel :</label>
          <input
            {...register("femail")}
            id="email"
            placeholder="Votre courriel"
            value={Email}
            onChange={(e) => emailFormat(e.target.value)}
          />
          <p className={StyledComponents.error}>
            {errors.femail?.type === "required" &&
              "⚠ Veuillez rentrer votre courriel"}
          </p>
          <p className={StyledComponents.error}>
            {errors.femail?.type === "email" &&
              "⚠ Veuillez rentrer un courriel valide"}
          </p>

          <label htmlFor="city">Ville :</label>
          <input
            {...register("fcity")}
            id="city"
            placeholder="Votre ville"
            value={City}
            onChange={(e) => setCity(e.target.value)}
          />
          <p className={StyledComponents.error}>
            {errors.fcity?.type === "required" &&
              "⚠ Veuillez rentrer votre ville"}
          </p>

          <label htmlFor="line1">Adresse complète :</label>
          <input
            {...register("faddress")}
            id="line1"
            placeholder="Votre adresse"
            value={Adress}
            onChange={(e) => setAdress(e.target.value)}
          />
          <p className={StyledComponents.error}>
            {errors.faddress?.type === "required" &&
              "⚠ Veuillez rentrer votre adresse complète"}
          </p>

          <label htmlFor="postalcode">Code postal :</label>
          <input
            {...register("fzip")}
            id="postalcode"
            placeholder="H3H 3H3"
            value={PostalCode}
            onChange={(e) => setPostalCode(e.target.value)}
          />
          <p className={StyledComponents.error}>
            {errors.fzip?.type === "required" &&
              "⚠ Veuillez rentrer votre code postal"}
          </p>

          <label htmlFor="state">Province/Territoire :</label>
          <select value={State} onChange={(e) => setState(e.target.value)}>
            <option value="" disabled selected>
              VOTRE PROVINCE / TERRITOIRE
            </option>
            {stateData.map((item) => {
              return (
                <option key={item} value={item}>
                  {item}
                </option>
              );
            })}
          </select>

          <div className={StyledComponents.ckbtn}>
            <button className={StyledComponents.ckbtn} type="submit">
              <p>Insérer une méthode de paiement</p>
            </button>
          </div>
        </form>
      </div>
      <div style={DisplayStates.cc}>
        <h3 className={StyledComponents.CCTitle}>Méthode de paiement</h3>
        {clientSecret && (
          <Elements options={options} stripe={stripePromise}>
            <CheckoutForm />
          </Elements>
        )}
      </div>
    </>
  );
};
