import React, { useState, useEffect } from "react";
import ItemsInCart from "../ItemsInCart/itemsInCart";
import CartItem from "../cartItem/cartItem";
import { TireCartItem } from "../tireCardCart/tireCardCart";
import { SensorCartCard } from "../sensorCartCard/sensorCartCard";
import { NutsCartCard } from "../nutsCartCard/nutsCartCard";
import { PromoCodes } from "../promoCodes/promoCodes";
import { SelectedVehicleComponent } from "../selectedVehicle/selectedvehicle";
import { getCart } from "../cartFunctions";
import { ShippingForm } from "../shippingForm/shippingForm";
import cart from "../../../icons/cart.svg";
import close1 from "../../../icons/close1.svg";
import * as StyledComponets from "./cart.module.scss";

import {
  modifyCartBtn,
  displayShippingBlock,
} from "../../setDisplayStates/functions";
import { getMVehicle } from "../cartFunctions";

import { getVersion } from "../cartFunctions";
//redux
import { useSelector, useDispatch } from "react-redux";
import allActions from "../../redux/actions";

// markup
const Cart = () => {
  //redux
  const DisplayStates = useSelector((state) => state.setDisplayStates);
  const NewCartContent = useSelector((state) => state.newCart);

  const dispatch = useDispatch();

  //cart display or not
  const [DisStyle, setDisStyle] = useState();

  //var to change the currency formats
  var formatter = new Intl.NumberFormat("fr-CA", {
    style: "currency",
    currency: "CAD",
  });

  //ON PAGE LOAD
  //When Total updates
  useEffect(() => {
    //get and updateversion
    getVersion("41322");
    //set the redux cart same as the local Storage
    let lscart = getCart();
    if (lscart !== undefined) {
      dispatch(allActions.cart.setNewCart(lscart));
    }

    //setMvehicle
    setMvehicle();
  }, [NewCartContent.total]);

  function setMvehicle() {
    //set the redux selectedVehicle as the local Storage
    let lssv = getMVehicle();
    //if vehicle is not undefined
    if (lssv !== undefined) {
      //if vehicle is not empty
      if (lssv.vehicle !== "") {
        //set selected vehicle on redux state
        dispatch(allActions.selectVehicle.setSelectedVehicle(lssv));
        //display shipping block
        let newDisplayStates = displayShippingBlock();
        dispatch(
          allActions.setDisplayStates.setDisplayStates(newDisplayStates)
        );
      }
    }
    //console.log("****************SETMVEHICLE : ");
    //console.log(SelectedVehicleDisplay);
    //console.log(DisplayStates);
  }

  //open cart
  function open() {
    document.body.style.overflow = "hidden";
    setDisStyle({ display: "flex" });
    setMvehicle();
  }

  //close cart
  function close() {
    document.body.style.overflow = "scroll";
    setDisStyle({ display: "none" });
  }

  //display nbr of items in cart
  function displayNbrItm() {
    if (NewCartContent.totalItems.qty === 0) {
      return;
    } else {
      return (
        <>
          <ItemsInCart />
        </>
      );
    }
  }

  //display tpms
  function displayTPMS() {
    if (NewCartContent.tpms.qty === 0) {
      return;
    } else {
      return <SensorCartCard numberOfSensors={NewCartContent.tpms.qty} />;
    }
  }

  //display nuts
  function displayNuts() {
    if (NewCartContent.nuts.qty === 0) {
      return;
    } else {
      return <NutsCartCard numberOfNuts={NewCartContent.nuts.qty} />;
    }
  }

  //calculate environment tax for tires
  function tiresTax() {
    if (NewCartContent.totalTires === 0) {
      return;
    } else {
      //get the totalTires
      let rep = NewCartContent.totalTires;
      //Calculate fees and change the value to financial
      rep = formatter.format(rep * 3);
      return (
        <div>
          <div className={StyledComponets.half}>
            <p>FRAIS ENVIRONNEMENTAUX :</p>
            <p>{rep}</p>
          </div>
        </div>
      );
    }
  }

  //calculate total in cart
  function cartSousTotal() {
    if (
      NewCartContent.totalItems === 0 &&
      NewCartContent.nuts.qty === 0 &&
      NewCartContent.tpms.qty === 0
    ) {
      return;
    }
    let res = (
      <div>
        <div className={StyledComponets.half}>
          <p>Sous-total :</p>
          <p>{formatter.format(NewCartContent.subTotal)}</p>
        </div>
      </div>
    );

    return res;
  }

  //Insert promo code and apply discount
  function promoCode() {
    //dont display if cart is empty
    if (
      NewCartContent.totalItems === 0 &&
      NewCartContent.nuts.qty === 0 &&
      NewCartContent.tpms.qty === 0
    ) {
      return;
    }
    return (
      <div>
        <PromoCodes />
      </div>
    );
  }

  //display the discount
  function discount() {
    //console.log(DisplayStates.shippingFormBlock);
    if (NewCartContent.discountAmount === 0) {
      return;
    }
    return (
      <div>
        <div className={StyledComponets.half}>
          <p>Rabais : </p>
          <p className={StyledComponets.rabais}>
            ({formatter.format(NewCartContent.discountAmount)})
          </p>
        </div>
        <div className={StyledComponets.half}>
          <p>Nouveau Sous-total :</p>
          <p>
            {formatter.format(
              NewCartContent.subTotal - NewCartContent.discountAmount
            )}
          </p>
        </div>
      </div>
    );
  }

  //TPS
  function tps() {
    if (NewCartContent.tps === 0) {
      return;
    }
    return (
      <div>
        <div className={StyledComponets.half}>
          <p>TPS : </p>
          <p>{formatter.format(NewCartContent.tps)}</p>
        </div>
      </div>
    );
  }

  //TVQ
  function tvq() {
    if (NewCartContent.tvq === 0) {
      return;
    }
    return (
      <div>
        <div className={StyledComponets.half}>
          <p>TVQ : </p>
          <p>{formatter.format(NewCartContent.tvq)}</p>
        </div>
      </div>
    );
  }

  //TOTAL
  function total() {
    if (NewCartContent.total === 0) {
      return;
    }
    return (
      <div>
        <div className={StyledComponets.half}>
          <p>Total : </p>
          <p>{formatter.format(NewCartContent.total)}</p>
        </div>
      </div>
    );
  }

  //modif cart button
  function modifCart() {
    let newDisplayStates = modifyCartBtn();
    dispatch(allActions.setDisplayStates.setDisplayStates(newDisplayStates));
  }

  return (
    <>
      {/*CART ICON*/}
      <button
        className={StyledComponets.icon}
        onClick={() => open()}
        aria-hidden="true"
      >
        <span uk-navbar-toggle-icon>
          <img src={cart} alt="cart" width="25px" />
        </span>
        {displayNbrItm()}
      </button>

      {/*CART CONTAINER*/}
      <div className={StyledComponets.cartContainer} style={DisStyle}>
        {/*EXIT SPACE*/}
        <div
          className={StyledComponets.exit}
          onClick={() => close()}
          aria-hidden="true"
        ></div>
        {/*CART*/}
        <div className={StyledComponets.cart}>
          {/*HEADER*/}
          <div className={StyledComponets.header}>
            <h1>PANIER</h1>
            <button className={StyledComponets.close} onClick={() => close()}>
              <img src={close1} alt="close" />
            </button>
          </div>

          {/*CART ITEMS*/}
          <div style={DisplayStates.cartItems}>
            {/*WHEELS*/}
            {NewCartContent.wheels.map((item) => {
              if (item.sku !== undefined) {
                return (
                  <CartItem
                    image={item.image}
                    brand={item.brand}
                    name={item.name}
                    stylenum={item.stylenum}
                    price={item.price}
                    diam={item.diam}
                    width={item.width}
                    offset={item.offset}
                    color={item.color}
                    finish={item.finish}
                    total={item.total}
                    sku={item.sku}
                    pcd1={item.pcd1}
                    pcd2={item.pcd2}
                  />
                );
              }
            })}
            {/*TIRES*/}
            {NewCartContent.tires.map((item) => {
              if (item.sku !== undefined) {
                return (
                  <TireCartItem
                    image={item.image}
                    brand={item.brand}
                    model={item.model}
                    price={item.price}
                    width={item.width}
                    aspect={item.aspect}
                    diam={item.diam}
                    total={item.total}
                    sku={item.sku}
                    pcd={item.pcd}
                  />
                );
              }
            })}
            {displayTPMS()}
            {displayNuts()}

            {/*FINCANCIAL*/}
            {promoCode()}
            {tiresTax()}
            {cartSousTotal()}
            {discount()}
            {tps()}
            {tvq()}
            {total()}

            <p className={StyledComponets.freeShipping}>
              &#x2611; Livraison gratuite &#x2611;
            </p>
            {/*SELECTED VEHICLE*/}
            <SelectedVehicleComponent />
          </div>

          {/*CHANGE CART BUTTON*/}
          <div
            className={StyledComponets.changeCartBtn}
            style={DisplayStates.changeCartBtn}
          >
            <button onClick={() => modifCart()}>MODIFIER VOTRE PANIER</button>
          </div>

          {/*SHIPPING FORM*/}
          <div style={DisplayStates.shippingFormBlock}>
            <ShippingForm />
          </div>
          <p className={StyledComponets.footer}>CANADA OFFROAD</p>
        </div>
      </div>
    </>
  );
};
export default Cart;
