import React from "react";
import { Helmet } from "react-helmet";
import LCartGlobalContext from "../../context/cartGlobalContext";
import { NavBar } from "../navbar/navbar";
import Footer from "../footer/footer";
import * as StyledComponents from "./layout.module.scss";

//SENTRY IMPORTS
import * as Sentry from "@sentry/browser";
import { ReportingObserver as ReportingObserverIntegration } from "@sentry/integrations";
import { BrowserTracing } from "@sentry/tracing";
import { CaptureConsole as CaptureConsoleIntegration } from "@sentry/integrations";

//React-redux
import { createStore } from "redux";
import rootReducer from "../redux/reducers";
import { Provider } from "react-redux";

const store = createStore(
  rootReducer
  //window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

//SENTRY CONFIGS
Sentry.init({
  dsn: "https://dd8e9973baf04d68be088d7825b91c9d@o1212710.ingest.sentry.io/6350927",
  integrations: [new CaptureConsoleIntegration(), new BrowserTracing()],
});

//Markup
export const PageLayout = ({ children }) => {
  return (
    <LCartGlobalContext>
      <Provider store={store}>
        <Helmet defer={false}>
          <title>
            CANADA OFFROAD | Trouvez des pneus et jantes pour votre véhicule
          </title>
          <meta
            name="og:image"
            content="https://canadaoffroad.com/static/carDesktop-6d7b5a29428ae398e177f69be8f63ef0.webp"
          />
          <meta
            name="facebook-domain-verification"
            content="cj9ib4tv3kddiyfiaw6nelbhvviiw7"
          />
          <meta
            name="description"
            content="Magasin en ligne de pneus et jantes à bas prix"
          />
          <meta
            name="og:title"
            content="CANDA OFFROAD | Trouvez des pneus et jantes pour votre véhicule"
          />
        </Helmet>
        <NavBar />
        <main className={StyledComponents.main}>{children}</main>
        <Footer />
      </Provider>
    </LCartGlobalContext>
  );
};
